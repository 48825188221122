.paginationContainer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}

.pagination {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(38, 50, 56, 0.08);
  padding: 6px 10px !important;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.pagination li {
  /* background: #f36623; */
  border: 1px solid #70b649;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  height: 35px;
  min-width: 35px;
  cursor: pointer;

  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.pagination li a {
  color: #9a9ea6;
  font-size: 14px;
  font-weight: 700;
}

.pagination li.active {
  border: 1px solid #70b649;
  background: #70b649;
}

.pagination li.active a {
  color: #fff;
}

.previous,
.next {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;

  border: 1px solid #9a9ea6 !important;
  background: #fff !important;
}

.previous a,
.next a {
  color: #9a9ea6;
}

.pagination .disabled {
  opacity: 0.5;
  pointer-events: none;
}
