/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"); */
@import url("./assets/font/stylesheet.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "GT Walsheim Pro";
}

html {
  scroll-behavior: smooth;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.loader_box {
  perspective: 120px;
}

.loader_plane {
  width: 5rem;
  height: 5rem;
  background-color: #fff;
  transform: rotate(0);
  animation: flip 1.4s infinite;
  border-radius: 0.8rem;
}

@keyframes flip {
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(180deg) rotateX(180deg);
  }
}
